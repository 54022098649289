


/*{
    display: grid;
    grid-template-rows: 70vh 50vh;
    max-width: 95vw;
    max-height: 95vh;
    width: 95vw;
    height: 95vh
}*/

.videoLayOut{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    
}

.espacoEntre{
    padding:calc(2.5px + 1vw);
    
}

