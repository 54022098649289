.foraGrid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@keyframes fadeDentro {
    from {opacity:0;
    }
    to {opacity:100;
        }
  }
@media only screen and (min-width: 1115px) {
.sobreMim{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /*grid-template-rows: 60vh;*/
    border: 10px;
    background-color: rgb(255, 255, 255);
}
}

@media only screen and (max-width: 1115px) {
    .sobreMim{
        display: grid;
        grid-template-rows: 130vh;
        border: 10px;
        background-color: rgb(255, 255, 255);
        align-items: center;
        justify-items: center;
    }
}

@media only screen and (min-width: 1115px) {
    .conteudo{
        display: grid;
        padding: 5vh;
        border: 4px;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
    
    }
}

@media only screen and (max-width: 1115px) {
    .conteudo{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 5vh;
        border: 4px;
        /*grid-template-rows: 50vh 1fr;*/
        align-items: center;
        justify-items: center;
    
    }
}

.eu{
    max-width: 400px;
    max-height: 400px;
    border: 1px solid rgba(202, 202, 202, 0.801);
    padding: 5px;
    border-radius: 2px;
    
}



.infoLayout{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.imgMax{
    max-width: 40px;
    max-height: 40px;
    padding: 5px;
    opacity: 0.7;
}

.imgMax:hover{

    opacity: 0.9;
    transition: 0.5s;
    
}