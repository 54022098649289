.footer{
    display: flex;
    flex-flow: row wrap;
    height: 10vh;
    max-width: 100vw;
    align-items: center;
    padding-left: 10vw;
    
}

