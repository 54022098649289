@keyframes fadeDentro {
    from {opacity:0;
    }
    to {opacity:100;
        }
  }

.front{
    display: grid;
    grid-template-rows: 7vh 93vh;
    width: 99vw;
    height: 99vh;
}

.fundo{
    

    background: 
    -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(12, 20, 88, 0.459)), color-stop(59%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(11, 60, 92, 0.329))),url(BrunoSky3F.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    
    
    background-size: cover;  
}

.fundo2{

    
    background: 
    -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(12, 20, 88, 0.459)), color-stop(59%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(11, 60, 92, 0.329))),url(bruno3N.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    
    
    background-size: cover;  
}

.buttonManage{
    grid-template-rows: 1fr 4fr 1fr;
    padding-top: 8vh;
    align-items: center;
    justify-content: start;
}




.coverAndTittle{
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
    margin-left: 10vw;
    margin-right: 10vw;
}
@media only screen and (min-width: 921px) {
.beginingBan{
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.banRight{
    background-color: rgba(11, 60, 92, 0.329);
    font-family: 'Anton', sans-serif;
    color: rgba(255, 255, 255, 0.863);
    text-align: left;
    animation-name: fadeDentro;
    animation-duration: 2s;
    
}
.textButton{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: left;
    margin: auto;
}

.espaco{
    padding:calc(2.5px + 1vw);
}
}

@media only screen and (max-width: 1115px) {
    .beginingBan{
        display: grid;
        grid-template-columns: 3fr 1fr;
    }
    
    .banRight{
        background-color: rgba(11, 60, 92, 0.329);
        font-family: 'Anton', sans-serif;
        color: rgba(255, 255, 255, 0.863);
        text-align: left;
        animation-name: fadeDentro;
        animation-duration: 2s;
        max-height: 25vh;
        
    }
    .textButton{
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        justify-content: left;
        margin: auto;
    }

    .espaco{
        padding:calc(2.5px + 1vw);
    }

}

img.react-images__view-image.react-images__view-image--isModal.css-1cm5myo.css-1ycyyax {
    max-height: 80vh;
}
